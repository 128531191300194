import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Btn from "../../components/button"
import BtnLeft from "../../components/button-left"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ShareButton from "../../components/share-button"

const ZyciorysIndex = ({ location }) => {
  const pageData = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/zyciorys/" }) {
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        html
      }
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(blog)/" }
          frontmatter: { inGallery: { eq: true } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            date(formatString: "YYYY-MM-DD")
            title
            description
          }
        }
      }
    }
  `)
  const posts = pageData.allMarkdownRemark.nodes
  const pageTitle = pageData.markdownRemark.frontmatter.title
  const pageBody = pageData.markdownRemark.html
  const featuredImage = getImage(
    pageData.markdownRemark.frontmatter.featuredImage
  )

  return (
    <Layout location={location} title={pageTitle}>
      <div className="section">
        <SEO title="Życiorys" />
        <div className="columns">
          <div className="column is-8 content-container has-background-dark is-6">
            <div className="columns is-mobile">
              <div className="column has-text-left ml-3">
                <BtnLeft
                  className=""
                  path={"/"}
                  description={"STRONA GŁÓWNA"}
                />
              </div>
              <div className="column has-text-right">
                <ShareButton location={location} />
              </div>
            </div>

            {/* <div className="is-flex is-justify-content-flex-end is-align-content-center mb-2 social-share-container"></div> */}

            <section className="section has-text-justified has-text-weight-light is-size-5 pb-4 pt-3">
              {/* <p className="has-dropcap"> {pageIntroduction}</p> */}
              <div className="in-text-img-wrapper">
                <GatsbyImage
                  objectFit="cover"
                  objectPosition="center"
                  image={featuredImage}
                  alt={`Wojciech Rutkowski`}
                  className="in-text-img"
                  style={{}}
                />
              </div>
              <div
                className="md-has-dropcap md-article"
                dangerouslySetInnerHTML={{ __html: pageBody }}
              />
            </section>
          </div>

          <div className="column is-4">
            <Btn
              path={"/galeria"}
              description={"ZAPRASZAMY DO GALERII OBRAZÓW"}
            ></Btn>
            <p className="subtitle is-6 is-family-secondary pt-6 mb-4 has-text-weight-light">
              WYBRANE OBRAZY
            </p>
            <ol style={{ listStyle: `none` }}>
              {posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug

                const featuredImage = getImage(post.frontmatter.featuredImage)

                return (
                  <li className="mb-5" key={post.fields.slug}>
                    <Link to={post.fields.slug} itemProp="url">
                      <section>
                        <GatsbyImage
                          objectFit="cover"
                          objectPosition="center center"
                          image={featuredImage}
                          alt={`Obraz - tytuł here`}
                          style={{}}
                        />
                      </section>
                      <div className="columns mb-0 is-mobile">
                        <div className="column has-text-centered">
                          <span
                            className="subtitle is-size-7 is-uppercase has-text-weight-light is-gold-link has-letter-spacing-1"
                            itemProp="headline"
                          >
                            {title}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ZyciorysIndex
